import React, { FunctionComponent, useState, useEffect } from "react";
import { Col, Dropdown, Row } from 'react-bootstrap'
import BaseButton from "../base-components/BaseButton";
import PersonImage from "./PersonImage";
import './PersonCard.scss';
import { IUserConnection, IUserOccasion } from '../../types/types-internal';
import { Link, useHistory } from "react-router-dom";
import { formatPrice } from '../../utils/CurrencyUtils';
import { IsNullOrEmpty } from "../../utils/Utils";
import { getOccasionDate } from '../event-info/functions/getOccasionDate';
import { RelationshipFormComponent } from "../../components/form-components/RelationshipFormComponent";
import editIcon from "../../assets/icons/edit.svg"
import checkMark from "../../assets/icons/checking-mark.svg"
import BaseHolidayDropdown from "../base-components/BaseHolidayDropdown";
import { CompareSharp } from "@material-ui/icons";
import axios from "axios";
import { useRecoilState } from "recoil";
import { selectedConnection } from "../../state/atoms";

type PersonCardProps = {
    personName: string,
    personIcon: string,
    personAlt: string,
    status?: string,
    event: string,
    budget: string,
    buttonIcon: string,
    buttonAlt: string,
    dropdownTitle: string,
    onRemoveConnection?: any,
    connectionToEdit?: IUserConnection,
    onEditFinished?: any,
    showChoseButton?: boolean,
    disabled?: boolean
}

const PersonCard: FunctionComponent<PersonCardProps> = ({disabled, personName, event, status, budget, personAlt, personIcon, buttonIcon, buttonAlt, dropdownTitle, onRemoveConnection, connectionToEdit, showChoseButton }) => {
    
    const [selectedConnectionState, setSelectedConnectionState] = useRecoilState(selectedConnection);
    const [occasions, setOccasions] = useState<IUserOccasion[]>([])
    const [chosenOccasion, setChosenOccasion] = useState<IUserOccasion>()
    const [userName, setUserName] = useState("")
    const [connection, setConnection] = useState(connectionToEdit);
    const history = useHistory();
    const [showSecondSpinner, setShowSecondSpinner] = useState(false)
    const [relationship, setRelationship] = useState<string>(connectionToEdit!.relationship! ?? status);
    const [isEditingRelationshipStatus, setIsEditRelationshipStatus] = useState<boolean>(false);
    const [disabledClass, setDisabledClass] = useState('');

    useEffect(()=>{
        if(disabled){
            setDisabledClass('disabled');
        }
    },[disabled])
    
    async function getUserConnectionOccasions(connectionId) {
        try {
            const userConnectionOccasionsResponse = await fetch('api/Occasions/GetUserOccasions?userConnectionId=' + connectionId)
            var userConnectionOccasions = await userConnectionOccasionsResponse.json()
            userConnectionOccasions = userConnectionOccasions.sort((a, b) => a.occasionDate > b.occasionDate)
            if (!chosenOccasion) {
                setChosenOccasion(userConnectionOccasions[0])
            }
            setOccasions(userConnectionOccasions)
        } catch (error) {
            console.log(error);
        }
    }

    function getBudget() {
        if (chosenOccasion) {
            return "Budget: " + formatPrice(chosenOccasion.budget)
        }
        let sortedOccasions = occasions.sort((a, b) => new Date(a.ocassionDate!).getTime() - new Date(b.ocassionDate!).getTime())
        if (typeof (sortedOccasions[0]) === "object") {
            return "Budget: " + formatPrice(occasions[0].budget)
        }
    }

    function onEditFinished(e) {
        if (!connection?.isAccessBlocked) {
            return;
        }
        setUserName(e.firstName + " " + e.lastName);
    }

    function getPersonName() {
        if (userName === "") {
            return personName
        }

        return userName
    }
    useEffect(() => {
        getUserConnectionOccasions(connectionToEdit?.connectionId);
        getProfileImage();
    }, [connectionToEdit]);

    //TODO: lift the state of the images, so we don't pull each time
    function getProfileImage() {
        if (connection?.imagePathUrl) {
            (async () => {
                let res = await fetch('api/Images/GetImage?url=' + connection.imagePathUrl)
                let imageUrl = await res.url;

                setConnection({ ...connection, profilePhotoDownloadUrl: imageUrl });
            })();

        }
        else {
            setConnection({ ...connection, profilePhotoDownloadUrl: undefined });
        }
    }

    function goToProfileWithOccasion() {
        if (connection?.isAccessBlocked) {
            return;
        }
        if (!chosenOccasion) {
            return
        }
        setSelectedConnectionState(connection!);
        setShowSecondSpinner(true)
        setTimeout(() => {
            setShowSecondSpinner(false);
            history.push({
                pathname: '/product-recommendations',
                search: `id=${connection?.connectionId}`,
                state: chosenOccasion
            })
        }, 500)
    }

    function editRelationshipStatus() {
        if (connection?.isAccessBlocked) {
            return;
        }
        setIsEditRelationshipStatus(!isEditingRelationshipStatus)

        if (isEditingRelationshipStatus && !IsNullOrEmpty(relationship)) {
            fetch("api/Users/UpdateUser", {
                "method": "PUT",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                },
                "body": JSON.stringify({
                    connectionId: connectionToEdit!.connectionId,
                    relationship: relationship,
                })
            })
                .then(response => {

                })
                .then(data => console.log(data))
                .catch(err => {
                    console.log(err);
                });
        }
    }

    
    function setUserRelationshipStatus(relationshipStatus) {
        if (connection?.isAccessBlocked) {
            return;
        }
        setRelationship(relationshipStatus)
        connectionToEdit!.relationship = relationshipStatus
    }
    const initials = getPersonName().split(" ").map((n) => n[0]).join("")
    let chooseSingleConnection = async () => {
        let result = await axios.put('/api/users/UpdateUser', { connectionId: connectionToEdit!.connectionId, isAccessBlocked: false })
        window.location.reload();
    }
    let colSize = showChoseButton ? 6 : 9;

    const goToConnectionprofile = () => {
        if (connectionToEdit?.connectionId) {
            setSelectedConnectionState(connection!);
            history.push('/product-recommendations?id=' + connectionToEdit.connectionId)
        }
    }

    return (
        <Row className={`person-card-container`} >
            <Col sm={12} md={colSize} className={`person-info-container ${disabledClass}`} >
                <PersonImage
                    personImg={connection?.profilePhotoDownloadUrl as string}
                    personAlt={personAlt}
                    personIcon={personIcon}
                    connectionToEdit={connectionToEdit}
                    onEditFinished={(e) => onEditFinished(e)}
                    onDelete={() => onRemoveConnection()}
                    initials={initials}
                    goToProfile={goToConnectionprofile}
                />
                <section className="person-info" onClick={goToConnectionprofile}>
                    <div className="person-info-content">
                        <h3 className="person-name" onClick={goToConnectionprofile}>{getPersonName()}</h3>
                        <div className="relationship-status-wrapper">
                            {
                                !isEditingRelationshipStatus ?
                                    <p>{relationship ? relationship : "Edit relationship status"}</p>
                                    :
                                    <RelationshipFormComponent relationship={relationship} setRelationship={setUserRelationshipStatus} />
                            }
                            <BaseButton
                                className={isEditingRelationshipStatus ? "save-button" : "edit-button"}
                                icon={isEditingRelationshipStatus ? checkMark : editIcon}
                                title=""
                                onClick={() => editRelationshipStatus()}
                            />
                        </div>
                        {getOccasionDate(chosenOccasion)}
                        <p>{getBudget()}</p>

                    </div>
                    <div className="gift-button-container">
                    </div>
                </section>
            </Col>
            {showChoseButton && <Col md={3}>
                <BaseButton title="Chose" className="choose-this-btn" onClick={chooseSingleConnection} />
            </Col>}

            <Col md={3} className={occasions[0] != undefined ? `other-gift-button-container ${disabledClass}` : "other-gift-button-container disabled"}>

                <BaseHolidayDropdown
                    selectedOccasion={{ occasion: chosenOccasion, setOccasion: setChosenOccasion }}
                    occasions={occasions} />
                <BaseButton
                    onClick={() => goToProfileWithOccasion()}
                    title=""
                    icon={buttonIcon}
                    alt={buttonAlt}
                    showSpinner={showSecondSpinner} />
            </Col>
        </Row>
    )
}

export default PersonCard;
