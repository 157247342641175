import './ManageConnectionModalContainer.scss';
import { useState } from "react";
import { IUserConnection } from "../../types/types-internal";
import YourConnectionModal from '../../components/modal/modal-your-connection/YouConnectionModal';
import ProfileCreatedModal from '../../components/modal/modal-profile-created/ProfileCreatedModal';
import EventModal from '../../components/modal/modal-event/EventModal';
import AddConnectionModal from '../../components/modal/modal-add-connection/AddConnectionModal';
import { ToggleButton } from 'react-bootstrap';
import BaseImage from '../../components/base-components/BaseImage';

type ManageConnectionModalContainerType = {
    addConnection?: Function,
    onNewConnection?: Function
}

const ManageConnectionModalContainer = ({
    addConnection,
    onNewConnection
}: ManageConnectionModalContainerType) => {

    const [userName, setUserName] = useState("")
    const [isAddConnection, setIsAddConnection] = useState(true);
    const [activeModal, setActiveModal] = useState(0);
    const [connectionId, setConnectionId] = useState()
    const [connection, setConnection] = useState<IUserConnection>()

    function onNewPlaceholderConnection(e) {
        setUserName(e.firstName + " " + e.lastName)
        setConnectionId(e.connectionId)
        setConnection(e)
        if (onNewConnection) {
            onNewConnection(0)
        }
    }

    function onNextModal() {
        setActiveModal(activeModal + 1);
    }

    function onHide() {
        //props?.onHide();
        setActiveModal(0);
    }

    const createConnectionBody = () => {
        return (
            <>
                {activeModal == 0 && <YourConnectionModal
                    title="Create Relationship Profile"
                    onNewConnection={onNewPlaceholderConnection}
                    addConnection={true}
                    onNextModal={onNextModal}
                />}
                {activeModal == 1 && <EventModal
                    connectionId={connectionId}
                    onNextModal={onNextModal} />}

                {activeModal == 2 && <ProfileCreatedModal
                    onHide={onHide}
                    user={{ userName: connection?.firstName + " " + connection?.lastName, connectionId: connectionId }}
                    connectionId={connection?.connectionId}
                    onNextModal={onNextModal} />}
            </>
        )
    }

    const onToggle = () => {
        setIsAddConnection(!isAddConnection);
    }

    const addConnectionVerify = (e) => {
        if (addConnection) {
            addConnection(e);
        }
    }
    return (
        <>
            {activeModal < 1 &&
                <>
                    <div className='toogle-wrapper'>   
                        <ToggleButton
                            type="checkbox"
                            variant="outline-dark"
                            checked={isAddConnection}
                            value="1"
                            onClick={() => onToggle()}
                        > 
                            <span className='add'></span>Add Relationship
                        </ToggleButton> 
                    
                        <ToggleButton
                            type="checkbox"
                            variant="outline-dark"
                            checked={!isAddConnection}
                            value="2"
                            onClick={() => onToggle()}
                        >
                            <span className='add-profile'></span>Create Relationship Profile
                        </ToggleButton>  
                    </div>
                
                    <div className='separator'></div>
                </>
            }
            {
                isAddConnection
                    ? <AddConnectionModal addConnection={(e) => addConnectionVerify(e)} />
                    : createConnectionBody()
            }
        </>

    );
}

export default ManageConnectionModalContainer;

