import React, { FunctionComponent, useEffect, useState, useContext } from "react";
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import './YouConnectionModal.scss';
import BaseButton from '../../base-components/BaseButton';
import ToggleButtonModal from '../modal-components/ToggleButtonModal';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import AvatarModal from '../modal-components/AvatarModal';
import HeaderModal from '../modal-components/HeaderModal';
import arrowRight from '../../../assets/icons/right-arrow.svg';
import { LocationFormComponent } from "../../form-components/LocationFormComponent";
import { DatePickerFormComponent } from '../../form-components/DatePickerFormComponent';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RelationshipFormComponent } from "../../form-components/RelationshipFormComponent";
import axios, { AxiosError } from "axios";

const YouConnectionModal = (props) => {

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");

    const [date, setDate] = useState<Date>();

    const [ageRange, setAgeRange] = useState("1");

    const [gender, setGender] = useState("");
    const [location, setLocation] = useState({ country: 'US', stateTeritory: 'State', city: '' });

    const [firstNameRequired, setFirstNameRequired] = useState(false)
    const [lastNameRequired, setLastNameRequired] = useState(false)
    const [genderRequired, setGenderRequired] = useState(false)
    const [emailValid, setEmailValid] = useState(true)

    const [locationRequired, setLocationRequired] = useState(false)
    const [profileCreationError, setProfileCreationError] = useState({ isError: false, errorText: '' });
    const [autoInvite, setAutoInvite] = useState(false)
    const [relationship, setRelationship] = useState<string>();
    const [showSpinner, setShowSpinner] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpen2 = () => {
        setOpen2(true);
    };

    function onGenderChange(gender) {
        setGender(gender)
        setGenderRequired(false)
    }

    function onNewPlaceholderConnection(e) {
        props.onNewConnection(e)
    }

    const [buttonTitle, setButtonTitle] = React.useState("")

    function setName() {
        if (firstName == "" && lastName == "") {
            return ""
        }

        return firstName + " " + lastName;
    }

    useEffect(() => {
        if (props.addConnection) {
            setButtonTitle("next: Gift Event")
        }
        else {
            setButtonTitle("Add Connection")
        }
    })

    async function nextModal() {
        if (firstName == "") {
            setFirstNameRequired(true)
            return
        }
        else {
            setFirstNameRequired(false)
        }

        if (lastName == "") {
            setLastNameRequired(true)
            return
        }
        else {
            setLastNameRequired(false)
        }

        if (email != "") {
            if (!/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-.]+\.[A-Za-z]+$/.test(email)) {
                setEmailValid(false)
                return
            }
        }

        if (gender == "") {
            setGenderRequired(true)
            return
        }

        if (location.country == "Country" || location.city == "") {
            setLocationRequired(true)
            return
        }
        let createResult = await createPlaceholderConnection();
        if (!createResult) {
            return
        }
        if(createResult) {
            setShowSpinner(true)
            setTimeout(() => {
                setShowSpinner(false);
                props.onNextModal();
            }, 500)
        }
    }
    async function createPlaceholderConnection() {

        try {
            let response = await axios.post('api/Users/AddPlaceholderUserConnection', {
                firstName: firstName,
                lastName: lastName,
                connectionEmail: email,
                gender: gender,
                birthday: date ? new Date(date) : undefined,
                ageRange: parseInt(ageRange),
                country: location.country,
                stateTerritory: location.stateTeritory,
                city: location.city,
                relationship: relationship
            });
            if(response.status != 200){
                return false;
            }

            onNewPlaceholderConnection(response.data)

            if (autoInvite && email) {
                await fetch('api/Auth/GenerateEmailToRegisterPlaceholderConnection?email=' + email);
            }
            return true;
        } catch (error: Error | AxiosError | any) {
            setProfileCreationError({ isError: true, errorText: error.response.data });
            return false;
        }
    }

    function onKeyDown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
            return
        }
    }

    return (
        <section className={`your-connection-modal active`}>
            {!props.addConnection && <ProgressLineModal />}
            <HeaderModal
                heading={props.title || "Who is the first person that you want to find gifts for?"}
                span=""
                paragraph=""
            />

            <Container fluid className="form-container">
                <Row>
                    <Col sm={12} lg={4} className="avatar-box">
                        <AvatarModal
                            userName={setName()}
                            alt="User image" />
                    </Col>
                    <Col sm={12} lg={8} className="avatar-info">
                        <Row>
                            <Col sm={12} lg={6}>
                                <form>
                                    <label>
                                        <input onKeyDown={(e) => onKeyDown(e)} onChange={(e) => setFirstName(e.currentTarget.value)} type="text" name="firstName" placeholder="First Name *" />
                                        <span className="required-modal-field" hidden={!firstNameRequired}> First name is required </span>
                                    </label>
                                </form>
                            </Col>
                            <Col sm={12} lg={6}>
                                <form>
                                    <label>
                                        <input onKeyDown={(e) => onKeyDown(e)} onChange={(e) => setLastName(e.currentTarget.value)} type="text" name="lastName" placeholder="Last Name *" />
                                        <span className="required-modal-field" hidden={!lastNameRequired}> Last name is required </span>
                                    </label>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={12} className="mt-3">
                                <form>
                                    <label>
                                        <input onKeyDown={(e) => onKeyDown(e)} onChange={(e) => setEmail(e.currentTarget.value)} type="text" name="email" placeholder="Email" />
                                        <Tooltip
                                            className="base-tooltip"
                                            arrow
                                            placement="top"
                                            title={<h6>Adding the connections email will help notify you when the user registers within Wise Prezzie  </h6>}
                                            open={open}
                                            onClose={handleClose}
                                            onClick={handleOpen}
                                            onOpen={handleOpen}
                                        >
                                            <IconButton aria-label="info">
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </label>
                                    <span className="required-modal-field" hidden={emailValid}> Provide a valid email </span>
                                    <FormControlLabel
                                        hidden={email == ""}
                                        control={<Checkbox color="primary" checked={autoInvite} onChange={() => setAutoInvite(!autoInvite)} name="autoInvite" />}
                                        label="Send an invitation automatically with the creation of the connection"
                                    />
                                </form>
                            </Col>
                            <Col>
                                <RelationshipFormComponent setRelationship={setRelationship} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5>Gender *</h5>
                                <ToggleButtonModal onGenderChange={onGenderChange} />
                                <span className="required-modal-field" hidden={!genderRequired}> Gender is required </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <DatePickerFormComponent value={date} onChange={setDate} />
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5><span>OR</span> Age range</h5>
                                <Row>
                                    <Col sm={12} md={4} className="data-picker-wrapper">
                                        <select className="DatePicker" placeholder="Age Range" onChange={(e) => setAgeRange(e.currentTarget.value)}>
                                            <option value="1">Below 21</option>
                                            <option value="2">22-30</option>
                                            <option value="3">31-40</option>
                                            <option value="4">41-50</option>
                                            <option value="5">51-60</option>
                                            <option value="6">61+</option>
                                        </select>
                                        <Tooltip className="base-tooltip-age-range"
                                            arrow
                                            placement="top"
                                            title={<h6>Select an age range if you are not sure about the exact age </h6>}
                                            open={open2}
                                            onClose={handleClose2}
                                            onClick={handleOpen2}
                                            onOpen={handleOpen2}
                                        >
                                            <IconButton aria-label="info">
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Col>
                                    <Col sm={12} md>
                                        {/* <Tooltip className="base-tooltip-age-range" arrow placement="top" title={<h6>Select an age range if you are not sure about the exact age </h6>}>
                                            <IconButton aria-label="info">
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip> */}
                                    </Col>
                                    <Col sm={12} md>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <LocationFormComponent location={location} setLocation={setLocation} />
                        <span className="required-modal-field" hidden={!locationRequired}> Location is required </span>
                        <span className="required-modal-field" hidden={!profileCreationError.isError}> {profileCreationError.errorText} </span>
                        <BaseButton
                            showSpinner={showSpinner}
                            onClick={async () => {await nextModal()} }
                            title={buttonTitle}
                            icon={arrowRight}
                            alt="Right Arrow"
                            className="arrow-animation"
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default YouConnectionModal;