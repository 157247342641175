import React, { FunctionComponent } from "react";
import HeaderModal from '../modal-components/HeaderModal';
import './AvatarModal.scss';

type AvatarProps = {
    userName: string,
    icon?: string,
    alt?: string,
    onProfileClick?: any,
    text?: string
}

const AvatarModal: FunctionComponent<AvatarProps> = ({ userName, icon, alt, onProfileClick }) => {

    const initials = userName.split(" ").map((n) => n[0]).join("")

    return (
        <div className="avatar-container"  onClick={() => onProfileClick(true)}>
            <div className="avatar-image-wrapper">
                {icon && <img src={icon} alt={alt} ></img>}
                {!icon && <div className="initials-view">{!initials ? "?" : initials }</div>}
            </div>
            <span>{userName}</span>
        </div>
    )
}

export default AvatarModal;