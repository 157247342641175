import { IUser, IUserConnection, IUserOccasion } from './../types/types-internal';
import { atom, selector, selectorFamily } from "recoil";

const userState = atom({
    key: 'UserState',
    default: {} as IUser
});

const selectedConnection = atom({
    key: 'SelectedConnection',
    default: {} as IUserConnection
});


export { userState, selectedConnection };