import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import './ForgottenPassword.scss';
import BaseButton from '../base-components/BaseButton';
import HeaderModal from '../modal/modal-components/HeaderModal';
import BaseFormInput from '../base-components/BaseFormInput';
import Alert from '@material-ui/lab/Alert';
import { useRecoilState } from "recoil";
import { userState } from "../../state/atoms";

export const ForgottenPassword = (props) => {
  
    const [user, setUser] = useRecoilState(userState)

    const [email, setEmail] = useState("")

    const [showSuccessAlert, setShowSuccessAlert] = useState(false)

    function sendResetLink()
    {
        setShowSuccessAlert(true)
        fetch('api/Auth/GenerateForgotPasswordEmailToken', 
        {
            'method': 'POST',
            'headers': {
                'content-type': 'application/json',
                'accept': 'application/json',
            },
            'body': JSON.stringify({
                email: props.fromSettings ? user.email : email,
            })
        })
        .then(response => response.json())
        .then(response => {
            console.log(response)
        })


        setTimeout(() => {
            props.onHide()
        }, 2500)
    }

    console.log('user is ' + JSON.stringify(user));
    function hideForgottenPassword(e)
    {
        e.preventDefault()
        props.onHide()
    }

    return (
        <section className="forgotten-password active">
            {
                props.fromSettings 
                ? 
                 <HeaderModal
                    heading="Reset Password"
                    span=""
                    paragraph=""
                />
                : 
                <HeaderModal
                    heading="Forgotten Password"
                    span=""
                    paragraph="Enter your email address below and we'll send you a link to reset your password."
                />
            }
           
            <Container fluid>
                <Row>
                    <Col>
                        <div className="form-container">
                            {
                                props.fromSettings 
                                ?
                                <div className="settings-reset-password">
                                    <p className="mb-2"> A reset password link will be sent to: </p>
                                    <p className="mb-4 p-email"> {user.email} </p>
                                </div>
                                :    
                                <BaseFormInput
                                    type="email"
                                    name="email"
                                    onChange={(e) => setEmail(e)}
                                    placeholder="Email Address" />
                            }
                        </div>
                        <BaseButton
                            onClick={sendResetLink}
                            title="Send reset link"
                            icon=""
                            alt="">
                        </BaseButton>
                        {
                            !props.fromSettings  &&
                            <p><a onClick={(e) => hideForgottenPassword(e)} href="#">Back to log in</a></p>
                        }

                        {   showSuccessAlert &&
                            <Alert severity="success" color="success" >
                                Reset password link sent.
                            </Alert>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ForgottenPassword;