import React, { FunctionComponent, useEffect, useState, useContext } from "react";
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import './AddConnectionModal.scss';
import HeaderModal from '../modal-components/HeaderModal';
import BaseFormInput from '../../base-components/BaseFormInput';
import PersonSearchResult from '../../../components/person-search-result/PersonSearchResult'
import loupeIcon from '../../../assets/icons/loupe.svg'
import { IUser } from "../../../types/types-internal";
import SendEmailPopUp from "../../../components/modal/modal-send-email/SendEmailPopUp"
import Alert from '@material-ui/lab/Alert';
import { Fade, Grow, Slide } from '@material-ui/core';
import BaseImage from "../../base-components/BaseImage";
import invitePlaceholder from '../../../assets/svg/invite-placeholder.svg';
import GoogleContacts from "react-google-contacts";
import ExternalConnectionsList from './ExternalConnectionsList';
import axios from "axios";

const AddConnectionModal = (props) => {

    function onNewPlaceholderConnection(e) {
        props.addConnection(e)
    }

    const [connectionName, setConnectionName] = useState("")
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [addConnectionError, setAddConnectionError] = useState(false)
    const [searchResults, setSearchResults] = useState<IUser[]>([{}])
    const [externalContacts, setExternalContacts] = useState<[]>();
    const [query, setQuery] = useState("")

    const importGoogleContacts = (resp) => {
        setExternalContacts(resp);
    }

    const errorLoadingGoogleContacts = (resp) => {
        console.log("Error loading: " + JSON.stringify(resp));
    }

    function loadOptions(query) {
        setQuery(query)
        if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(query)) // if query is email
        {
            return fetch('api/Users/GetRealUsersByEmail?email=' + query, {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                }
            })
                .then((res) => res.json())
                .then(res => {
                    setSearchResults(res)
                })
        }
        else {
            return fetch('api/Users/GetAllRealUsersByName', {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                },
                "body": JSON.stringify({
                    firstName: query.split(" ")[0],
                    lastName: query.split(" ")[1] || query,
                    email: ""
                })
            })
                .then((res) => res.json())
                .then(res => {
                    setSearchResults(res)
                })
        }
    };

    function showSuccessAlerts(code) {
        if (code == 400) {
            setAddConnectionError(true)
        }

        setShowSuccessAlert(true)

        setTimeout(() => {
            setShowSuccessAlert(false)
            setAddConnectionError(false)
        }, 3000);
    }

    async function chooseConnection(value, relationship) {
        value["relationship"] = relationship

        setConnectionName(value.firstName)
        let id = value.userId
        if (id == undefined) {
            id = value.id
        }

        try {
            let addResponse = await axios.post('api/Users/AddRealUserConnection?realUserId=' + id);
            if (addResponse.status == 200) {
                props.addConnection(value)
                setShowSuccessAlert(true)
            }
            if (relationship) {
                let updateRelationshipResponse = await axios.post('api/Users/UpdateUser', {
                    connectionId: addResponse.data.connectionId,
                    relationship: relationship,
                });
            }
        } catch (error) {
            setAddConnectionError(true);
        }

        setTimeout(() => {
            setShowSuccessAlert(false)
            setAddConnectionError(false)
        }, 3000);
    }

    return (
        <section className={props.addConnection ? "add-connection-modal active" : "add-connection-modal"}>
            <HeaderModal
                heading={props.title || "Add relationships"}
                span=""
                paragraph=""
            />
            <Container>
                <Row>
                    <Col xl={12} className="search-container">
                        <BaseFormInput
                            type="text"
                            name="findConnection"
                            onChange={(e) => loadOptions(e)}
                            src={loupeIcon}
                            placeholder="Find a relationship"
                        />
                        <Fade in={addConnectionError} timeout={500}>
                            {
                                <Alert severity="error" color="error" >
                                    Cannot add connection your connection list.
                                </Alert>
                            }
                        </Fade>
                        <Fade in={showSuccessAlert} timeout={500}>
                            {
                                <Alert severity="success" color="success" >
                                    {connectionName} added to your connection list.
                                </Alert>
                            }
                        </Fade>
                        <div className={(query != "") ? "search-results" : "search-results hidden"}>

                            {(searchResults[0]["userId"] == null)
                                ?
                                <div className="placeholder-image">
                                    <BaseImage
                                        img={invitePlaceholder}
                                    />
                                </div>
                                :
                                searchResults.map(result => (
                                    <PersonSearchResult
                                        key={result["userId"]}
                                        personName={result["firstName"] + " " + result["lastName"]}
                                        personIcon={result.imagePathUrl}
                                        personState={result["state"]}
                                        personCity={result["city"]}
                                        personCountry={result["country"]}
                                        onClick={(relationship) => chooseConnection(result, relationship)}
                                    />
                                ))}

                        </div>
                    </Col>
                </Row>
                <Row className="buttons-container">
                    <p>Can't find someone?</p>
                    <Col lg={12}>
                        <SendEmailPopUp
                            title={"Invite via email"}
                            icon=""
                            user={{ userName: "undefined" }}
                        />
                    </Col>
                    <Col lg={12} className="import-contacts-container">
                        <GoogleContacts
                            className="social-button"
                            clientId="58223197108-3d92p6jqjo9skufsdfh16oopsrc7mn2a.apps.googleusercontent.com"
                            buttonText="Import Contacts"
                            onSuccess={importGoogleContacts}
                            onFailure={errorLoadingGoogleContacts} /></Col>
                    {externalContacts
                        ? <div className={"search-results"}>
                            <Col lg={12}>
                                <ExternalConnectionsList
                                    externalContacts={externalContacts}
                                    showSuccessAlerts={(code) => showSuccessAlerts(code)}
                                    addConnection={onNewPlaceholderConnection} />
                            </Col>
                        </div>
                        : null}
                </Row>
            </Container>
        </section>
    )
}

export default AddConnectionModal;
