import React, { useContext } from "react";
import './ProfileCreatedModal.scss';
import Image from '../../base-components/BaseImage';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import AvatarModal from '../modal-components/AvatarModal';
import HeaderModal from '../modal-components/HeaderModal';
import avatarCreated from '../../../assets/icons/avatar-created.svg'
import congratulations from '../../../assets/svg/congratulations.svg'
import { useHistory } from "react-router-dom";
import { IsNullOrEmpty } from "../../../utils/Utils";
import { useRecoilState } from 'recoil'
import { selectedConnection, userState } from "../../../state/atoms";
import BaseButton from "../../base-components/BaseButton";
import arrowRight from '../../../assets/icons/right-arrow.svg';
const ProfileCreatedModal = (props) => {

    const history = useHistory();
    const [user, setUser] = useRecoilState(userState)
    const [selectedConnectionState, setSelectedConnectionState] = useRecoilState(selectedConnection);

    function userName() {
        return IsNullOrEmpty(props.user.userName) ? user.firstName + " " + user.lastName : props.user.userName;
    }

    function goToProfile() {
        if (props?.connectionId) {
            history.push({
                pathname: '/product-recommendations',
                search: `id=${props?.connectionId}`,
            })
            setSelectedConnectionState({ ...selectedConnectionState, connectionId: props.connectionId });
        } else {
            history.push({
                pathname: '/product-recommendations'
            })
        }
        props?.onHide();
    }

    return (
        <section className={`profile-created-modal mb-2 active`}>
            <Image
                img={congratulations}
                alt="" />
            <ProgressLineModal />
            <HeaderModal
                span=""
                heading={userName() + "'s profile is set up!"}
                paragraph="Now let’s go look at some gift recommendations."
            />
            <div className="go-to-profile-wrapper" onClick={goToProfile}>
                <AvatarModal
                    userName="You"
                    icon={avatarCreated}
                    onProfileClick={() => goToProfile()}
                    alt="User image" />
                {/* <span className="go_to_profile">Go to profile</span> */}
                <BaseButton title="Go to profile"
                    className="go-to-profile-button arrow-animation"
                    icon={arrowRight}
                    alt="Go to profile"
                    onClick={goToProfile} />
            </div>
        </section>
    )
}

export default ProfileCreatedModal;