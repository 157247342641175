import React, { useState, useEffect } from "react";
import {  Row, Col } from 'react-bootstrap';
import './TryModal.scss';
import BaseButton from '../../base-components/BaseButton';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import HeaderModal from '../modal-components/HeaderModal';
import arrowRight from '../../../assets/icons/right-arrow.svg';
import { IsNullOrEmpty } from '../../../utils/Utils';
import axios, { AxiosError } from 'axios';
import { stringifyErrorMessage } from '../../../utils/errorHandling';
import { useRecoilState } from 'recoil'
import { userState } from "../../../state/atoms";

const TryModal = ({ userValues, onNextModal }) => {

    const [user, setUser] = useRecoilState(userState)
    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [touched, setTouched] = React.useState<any>({});
    const [errors, setErrors] = React.useState<any>({});
    const [values, setValues] = React.useState(userValues);
    const [invitedBy, setInvitedBy] = useState<string>();
    const [loading, setLoading] = React.useState(false)

    // change event handler
    const handleChange = evt => {
        const { name, value: newValue, type } = evt.target;

        // keep number fields as numbers
        const value = type === "number" ? + newValue : newValue;

        // save field values
        setValues({
            ...values,
            [name]: value
        });

        // was the field modified
        setTouched({
            ...touched,
            [name]: true
        });
    };

    // form submit handler
    const handleSubmit = (evt, isRealUser) => {
        setLoading(true)
        evt.preventDefault();

        if (values.firstName == "") {
            setErrors({ firstName: "First name is required" })
        }
        if (values.lastName == "") {
            setErrors({ lastName: "Last name is required" })
        }

        if (values.firstName == "" || values.lastName == "") {
            setLoading(false)
            return
        }
        // validate the form
        const formValidation = Object.keys(values).reduce(
            (acc, key) => {
                const newError = key != "isRealUser" && validate[key](values[key]);

                const newTouched = { [key]: true };
                return {
                    errors: {
                        ...acc.errors,
                        ...(newError && { [key]: newError })
                    },
                    touched: {
                        ...acc.touched,
                        ...newTouched
                    }
                };
            },
            {
                errors: { ...errors },
                touched: { ...touched }
            }
        );
        setErrors(formValidation.errors);
        setTouched(formValidation.touched)
        registerUser(isRealUser)
    };

    useEffect(() => {
        var url = new URL(window.location.toString())
        if (url.toString().includes("invite-connection")) {

            let email = url.searchParams.get("invited-by")

            if (!IsNullOrEmpty(email)) {
                setInvitedBy(email!)
            }
        }
    }, []);

    async function registerUser(isRealUser) {
        if (password != confirmPassword) {
            setLoading(false)
            return
        }

        if (values.firstName === "") {
            setErrors({ ...errors, firstName: "First name is required" })
        }

        try {
            let reg = {
                email: values.email,
                password: password,
                firstName: values.firstName,
                lastName: values.lastName,
                invitedByEmail: invitedBy,
            };
            const response = await axios.post("api/Auth/Register", reg);
            if (!response.data.userId) {
                if (response.data[0] != undefined) {
                    setErrors({ password: response })
                }

                if (response.data.errors["Password"] != undefined) {
                    setErrors({ password: response.data.errors["Password"] })
                }
                if (response.data.errors["Email"] != undefined) {
                    setErrors({ email: response.data.errors["Email"][0] })
                }
            }
            else {
                const currentUserId = response.data.userId as string;

                let firstName = response.data.firstName;
                let lastName = response.data.lastName;

                setUser({
                    firstName: firstName,
                    lastName: lastName,
                    userName: firstName + " " + lastName,
                    userId: currentUserId,
                    isLoggedIn: true
                });
                onNextModal();
            }
        }
        catch (error) {
            setErrors({ password: stringifyErrorMessage(error) })
            setLoading(false)
        }
    }

    const firstNameValidation = (firstName) => {

        if (firstName.trim() === "") {
            console.warn("First name is required")
            return ("First name is required")
        }

        return null;
    };

    const lastNameValidation = (lastName) => {

        if (lastName.trim() === "") {
            console.warn("Last name is required")
            return ("Last name is required")
        }

        return null;
    };

    const emailValidation = email => {
        if (
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
            )
        ) {
            return null;
        }
        if (email.trim() === "") {
            console.warn('Email is required')
            return "Email is required";
        }
        console.warn('Please enter a valid email')

        return "Please enter a valid email";
    };

    const passwordValidation = pass => {

        if (password === "") {
            console.warn('Password is required')
            return 'Password is required'
        }
        else if (confirmPassword === "") {
            console.warn('Repeat Password is required')
            return 'Repeat password is required'
        }

        if (password !== confirmPassword) {
            console.warn(password, " Passwords don't match ", confirmPassword)
            return "Passwords don't match"
        }
    }

    const validate = {
        firstName: name => firstNameValidation(values.firstName),
        lastName: name => lastNameValidation(values.lastName),
        email: emailValidation,
        password: passwordValidation
    };

    return (
        <section className="try-modal active">
            <ProgressLineModal />
            <HeaderModal
                heading="Ok! Let’s get started"
                span=""
                paragraph="First thing’s first. Let’s create a profile for you to see how WisePrezzie gift recommendations work"
            />
            <form>
                <Row>
                    <Col sm={12} md={6}>
                        <label className="error-message-text-red">
                            <input
                                inputMode="text"
                                type="text"
                                name="firstName"
                                placeholder="First name *"
                                value={values.firstName}
                                onChange={handleChange}
                                required />

                            {touched.firstName && errors.firstName}
                        </label>
                    </Col>
                    <Col sm={12} md={6}>

                        <label className="error-message-text-red">
                            <input
                                type="text"
                                placeholder="Last name *"
                                value={values.lastName}
                                onChange={handleChange}
                                name="lastName"
                                required />

                            {touched.lastName && errors.lastName}
                        </label>

                    </Col>
                </Row>
                <Row>
                    <Col>

                        <label className="error-message-text-red">
                            <input
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                name="email"
                                placeholder="Email Address *"
                            />
                            {touched.email && errors.email}
                        </label>

                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <label className="error-message-text-red">
                            <input
                                type="password"
                                inputMode="text"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Password *"
                            />

                            {touched.password && errors.password}

                        </label>
                    </Col>
                    <Col sm={12} md={6}>
                        <label className="error-message-text-red">
                            <input
                                type="password"
                                inputMode="text"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                placeholder="Repeat Password *"
                            />
                            {touched.confirmPassword && errors.confirmPassword}

                        </label>
                    </Col>
                </Row>
            </form>

            <BaseButton
                onClick={(e) => handleSubmit(e, true)}
                title="Create account"
                icon={arrowRight}
                alt="Right Arrow"
                showSpinner={loading}
                className="arrow-animation">
            </BaseButton>
        </section>
    )
}

export default TryModal;