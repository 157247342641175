import editIcon from '../../assets/icons/edit.svg'
import event1 from '../../assets/images/event1.jpg'
import event2 from '../../assets/images/event2.jpg'
import event3 from '../../assets/images/event3.jpg'
import event4 from '../../assets/images/event4.jpg'
import event5 from '../../assets/images/event5.jpg'
import event6 from '../../assets/images/event6.jpg'
import event7 from '../../assets/images/event7.jpg'
import event8 from '../../assets/images/event8.jpg'
import event9 from '../../assets/images/event9.jpg'
import event10 from '../../assets/images/event10.jpg'
import event11 from '../../assets/images/event11.jpg'
import event14 from '../../assets/images/event14.png'
import birthdayImg from '../../assets/images/birthday.jpg'
import thanksgivvingImg from '../../assets/images/thanksgiving.jpg'
import xmasImg from '../../assets/images/xmas.jpg'
import newyearImg from '../../assets/images/newyear.jpg'
import justbecauseImg from '../../assets/images/just.jpg'
import React, { useState } from 'react'
import EventItemModal from '../modal/modal-components/EventItemModal';
import noEventGift from '../../assets/images/no-event-gift.png';
import { DateUtils } from '../../utils/DateUtils'

const OccasionEventsListComponent = (props) => {

    function displayBudget(budget) {
        return budget == null ? "100" : budget
    }

    function getImage(occasion) {
        var image = xmasImg
        console.log('OCC ' + JSON.stringify(occasion))

        if (occasion.typeOfOccasion === "Christmas") {
            image = xmasImg
        }
        else if (occasion.typeOfOccasion === "Thanksgiving") {
            image = thanksgivvingImg
        }
        else if (occasion.typeOfOccasion === "NewYearsDay") {
            image = newyearImg
        }
        else if (occasion.typeOfOccasion === "Birthday") {
            image = birthdayImg
        }
        else if (occasion.typeOfOccasion === "JustBecause") {
            image = justbecauseImg
        }
        else if (occasion.typeOfOccasion === "ValentinesDay") {
            image = event2
        }
        else if (occasion.typeOfOccasion === "Easter") {
            image = event3
        }
        else if (occasion.typeOfOccasion === "Hannukah") {
            image = event9
        }
        else if (occasion.typeOfOccasion === "Kwanzaa") {
            image = event11
        }
        else if (occasion.typeOfOccasion === "MothersDay") {
            image = event4
        }
        else if (occasion.typeOfOccasion === "FathersDay") {
            image = event5
        }
        else if (occasion.typeOfOccasion === "IndependenceDay") {
            image = event6
        }
        else if (occasion.typeOfOccasion === "SweetestDay") {
            image = event7
        }
        else if(occasion.typeOfOccasion == "Anniversary"){
            image = event14
        }
        return image
    }
    const [activeId, setActiveId] = useState("0");
    let radioActive = {
        activeId: activeId,
        setActiveId: setActiveId
    }

    return (
        <>
            <EventItemModal
                    radioActive={radioActive}
                    radioId={'00'}
                    label={'No Occasion'}
                    image={noEventGift}
                    alt={'No Occasion'}
                    budget={'For any time'}
                    onRadioBtnClick={props.onRadioBtnClick}
                />
            {props.occasions.map(occ => (
                <EventItemModal
                    radioActive={radioActive}
                    radioId={occ.occasionId}
                    label={occ.typeOfOccasion}
                    image={getImage(occ)}
                    alt={occ.typeOfOccasion}
                    budget={displayBudget(occ.budget)}
                    onDelete={() => props?.onDeleteOccasion(occ)}
                    onEdit={(budget, date) => props?.onEditOccasionBudget(occ, budget, date)}
                    date={occ.ocassionDate}
                    onRadioBtnClick={props.onRadioBtnClick}
                />

            ))}
        </>
    )
}

export default OccasionEventsListComponent;