import React, { useState, useEffect } from "react";
import './GiftEventsModal.scss';
import ProgressLineModal from '../modal-components/ProgressLineModal';
import HeaderModal from '../modal-components/HeaderModal';
import BaseInput from "../../base-components/BaseFormInput";
import SearchItemModal from "../modal-components/SearchItemModal";
import SaveCancelButtons from "../modal-components/SaveCancelButtons";
import event1 from '../../../assets/images/event1.jpg'
import event2 from '../../../assets/images/event2.jpg'
import event3 from '../../../assets/images/event3.jpg'
import event4 from '../../../assets/images/event4.jpg'
import event5 from '../../../assets/images/event5.jpg'
import event6 from '../../../assets/images/event6.jpg'
import event7 from '../../../assets/images/event7.jpg'
import event8 from '../../../assets/images/event8.jpg'
import event9 from '../../../assets/images/event9.jpg'
import event10 from '../../../assets/images/event10.jpg'
import event11 from '../../../assets/images/event11.jpg'
import event14 from '../../../assets/images/event14.jpg'
import birthdayImg from '../../../assets/images/images-sm/birthday-sm.jpg';
import justBecauseImg from '../../../assets/images/images-sm/just-because-sm.jpg';
import { IUserOccasion, IUserConnection, IUserHoliday } from "../../../types/types-internal";
import { DateUtils } from "../../../utils/DateUtils";
import { publicHolidaysKvP, sweetsDayDate } from "../../../utils/Utils";
import Holidays from "date-holidays";

const GiftEventsModal = (props) => {
    const [selectedOccasion, setSelectedOccasion] = useState<IUserOccasion>();
    const [connectionId, setConnectionId] = useState(0);

    const publicHolidays: IUserHoliday[] =
        [
            { occasionId: 0, added: false, budget: 100, occasionName: "New Year's Eve", typeAsNumber: 3, typeOfOccasion: "NewYearsDay", ocassionDate: DateUtils.today(), image: event1 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Valentine's Day", typeAsNumber: 4, typeOfOccasion: "ValentinesDay", ocassionDate: DateUtils.today(), image: event2 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Easter", typeAsNumber: 5, typeOfOccasion: "Easter", ocassionDate: DateUtils.today(), image: event3 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Mother's Day", typeAsNumber: 6, typeOfOccasion: "MothersDay", ocassionDate: DateUtils.today(), image: event4 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Father's Day", typeAsNumber: 7, typeOfOccasion: "FathersDay", ocassionDate: DateUtils.today(), image: event5 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Independence Day", typeAsNumber: 8, typeOfOccasion: "IndependenceDay", ocassionDate: DateUtils.today(), image: event6 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Sweetest Day", typeAsNumber: 9, typeOfOccasion: "SweetestDay", ocassionDate: DateUtils.today(), image: event7 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Thanksgiving", typeAsNumber: 1, typeOfOccasion: "Thanksgiving", ocassionDate: DateUtils.today(), image: event8 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Hanukkah", typeAsNumber: 10, typeOfOccasion: "Hannukah", ocassionDate: DateUtils.today(), image: event9 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Christmas", typeAsNumber: 2, typeOfOccasion: "Christmas", ocassionDate: DateUtils.today(), image: event10 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Kwanzaa", typeAsNumber: 11, typeOfOccasion: "Kwanzaa", ocassionDate: DateUtils.today(), image: event11 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Birthday", typeAsNumber: 12, typeOfOccasion: "Birthday", ocassionDate: DateUtils.today(), image: birthdayImg },
            { occasionId: 0, added: false, budget: 100, occasionName: "Anniversary", typeAsNumber: 14, typeOfOccasion: "Anniversary", ocassionDate: DateUtils.today(), image: event14 },
            { occasionId: 0, added: false, budget: 100, occasionName: "Just Because", typeAsNumber: 13, typeOfOccasion: "JustBecause", ocassionDate: DateUtils.today(), image: justBecauseImg },
        ]

    const [holidays, setHolidays] = useState<IUserHoliday[]>(publicHolidays.map(x => generateHolidayDate(x)));

    useEffect(() => {
        var url = new URL(window.location.toString())
        let id = Number(url.searchParams.get("id"));
        setConnectionId(id)
    }, [])

    useEffect(() => {
        let updatedHolidays = holidays.map(h => {
            if (props.occasions.find(o => o.typeOfOccasion == h.typeOfOccasion)) {
                return { ...h, added: true }
            }
            return h;
        });
        setHolidays(updatedHolidays);
    }, [props.occasions])

    function onGiftEventClick(occasion: IUserOccasion) {
        setSelectedOccasion(occasion);
    }

    function generateHolidayDate(occasionHoliday: IUserHoliday) {
        var hd = new Holidays('US')
 
       if (occasionHoliday?.typeOfOccasion == "SweetestDay") {
            let sweetestDay = sweetsDayDate();
            occasionHoliday.ocassionDate = DateUtils.localToUtc(sweetestDay);
        }
        else if (occasionHoliday?.typeOfOccasion == "Kwanzaa") {
            var kwanzaaDate = new Date(new Date().getFullYear(), 11, 26);

            if (new Date() > kwanzaaDate) {
                kwanzaaDate.setFullYear(kwanzaaDate.getFullYear() + 1)
            }

            occasionHoliday.ocassionDate = DateUtils.localToUtc(kwanzaaDate);
        }
        else if (occasionHoliday?.typeOfOccasion == "Birthday") {
            if (props.connection?.birthday) {
                var birthday = DateUtils.getNextCelebrationDate(props.connection.birthday);
                occasionHoliday.ocassionDate = birthday;
            }
        }
        else {
            var typeOfOccasion = occasionHoliday?.typeOfOccasion;
            var publicHoliday = publicHolidaysKvP.find(h => h.key == typeOfOccasion)?.value;

            let holidaysThisYear = hd.getHolidays(new Date().getFullYear());
            let holidaysNextYear = hd.getHolidays(new Date().getFullYear() + 1);
            let holidays = [...holidaysThisYear, ...holidaysNextYear];

            let today = DateUtils.today();
            let holiday = holidays.find(hol => parseHolidayDate(hol) >= today && hol.name == publicHoliday);
            if (holiday) {
                let holidayDate = parseHolidayDate(holiday);
                occasionHoliday.ocassionDate = DateUtils.localToUtc(holidayDate);
            }
            else {
                occasionHoliday.ocassionDate = DateUtils.localToUtc(today);
            }
        }
        return occasionHoliday;
    }

    function parseHolidayDate(holiday){
        return new Date(holiday.date.split(' ')[0]);
    }

    const addOccasion = () => {
        if (selectedOccasion) {
            if (props.occasions.some(x => x == selectedOccasion)) {

            }
            else {
                (async () => {
                    const settings = {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            budget: selectedOccasion?.budget ? selectedOccasion?.budget : 0,
                            ocassionDate: selectedOccasion?.ocassionDate,
                            typeOfOccasion: holidays.filter(hol => hol.typeOfOccasion === selectedOccasion?.typeOfOccasion).map(occ => occ.typeAsNumber)[0],
                            connectionId: props?.connection?.connectionId || connectionId,
                        })
                    };
                    let respData = await fetch('api/Occasions/AddOccasionToUser', settings);
                    let data = respData.json();
                    data.then(res => {
                        selectedOccasion.occasionId = res.occasionId

                        if (props.isActive) {
                            props.onEditedOccasions(selectedOccasion)
                            props.onHide()
                        }
                    })
                })();
            }
        }
    }

    function hideModal() {
        props.onHide()
    }

    function setBudget(budget, holiday) {
        if (budget < 100) {
            budget = 100
        }

        let holidaysCopy = holidays
        var hol = holidays.find(hol => hol.typeOfOccasion == holiday.typeOfOccasion)
        hol!.budget = budget
        setHolidays(holidaysCopy);
    }

    function onDateChange(date) {
        if (selectedOccasion) {
            let updatedOccasionDate = selectedOccasion;
            updatedOccasionDate.ocassionDate = date;
            setSelectedOccasion(updatedOccasionDate);
        }
    }

    return (
        <section className={props.isActive ? "gift-events-modal active" : "gift-events-modal"}>
            {!props.isActive && <ProgressLineModal />}
            <HeaderModal
                heading="Tell us which events you want us 
                to help you find gifts for."
                span=""
                paragraph="Wiseprezzie will use this info to not only find gift recommendations,
                but also share reminders and keep you within any budget you set" />
            <section className="gifts-group-container">
                {
                    holidays.map((holiday, index) => (
                        <div key={index} className="gift-item" >
                            <SearchItemModal
                                active={holiday.typeOfOccasion == selectedOccasion?.typeOfOccasion}
                                occasionHoliday={holiday}
                                img={holiday.image}
                                label = {holiday.occasionName}
                                alt={holiday.occasionName}
                                onClick={() => onGiftEventClick(holiday)}
                                onDateChange={(date) => onDateChange(date)}
                                connection={props.connection}
                                added={holiday.added}
                            />
                            {!holiday.added && <BaseInput
                                className="optional-input"
                                value={selectedOccasion?.budget}
                                type="number"
                                name="text"
                                onChange={(e) => setBudget(e, holiday)}
                                placeholder="(Optional) Budget" />}

                        </div>
                    ))
                }
            </section>
            <SaveCancelButtons
                saveClick={() => addOccasion()}
                cancelClick={() => hideModal()} />
        </section >
    )
}

export default GiftEventsModal;