import { FunctionComponent, useEffect, useState } from "react";
import './UserProfileContainer.scss';
import { Col, Container, Row } from 'react-bootstrap'
import PersonImage from "../person-card/PersonImage";
import BaseTooltip from "../base-components/BaseTooltip";
import BaseHolidayDropdown from "../base-components/BaseHolidayDropdown";
import EditProfileComponent from './EditProfileComponent'
import { IUser, IUserOccasion, } from '../../types/types-internal';
import { IUserConnection } from '../../types/types-internal';
import avatarGrey from '../../assets/icons/avatar-grey.svg'
import emailIcon from '../../assets/icons/email.svg'
import EventInfo from '../event-info/EventInfo'
import SendEmailPopUp from "../modal/modal-send-email/SendEmailPopUp"

type UserProfileProps = {
    connection: IUserConnection | IUser
    updateConnection: any,
    occasions?: any,
    editedOccasions?: boolean,
    selectedOccasion?: { occasion?: IUserOccasion, setOccasion?: Function }
}

const UserProfileContainer: FunctionComponent<UserProfileProps> = ({ connection, updateConnection, occasions, editedOccasions, selectedOccasion }) => {

    let names = `${connection.firstName} ${connection.lastName == null ? "" : connection.lastName}`;    
    const initials = names.split(" ").map((n) => n[0]).join("") //TODO see if this crashes

    return (
        <>
            <section className="profile-container">
                <Container>
                    <Row>
                        <Col xs={12} lg={5} className="profile-info-container">
                            <PersonImage
                                personImg={connection?.profilePhotoDownloadUrl as string}
                                personAlt="User"
                                initials={initials}
                                personIcon={avatarGrey} />
                            <section className="profile-info">
                                <span>Gifts for</span>
                                <h3>{names}</h3>

                                <p>{connection?.relationship}</p>
                                <div className="buttons-wpapper">
                                    <EditProfileComponent
                                        profile={connection}
                                        updateProfile={updateConnection} />
                                    {!connection.isRealUser &&
                                        <div className="send-email-wrapper">
                                            <SendEmailPopUp
                                                title=""
                                                icon={emailIcon}
                                                user={connection}
                                            />
                                            <BaseTooltip
                                                title={"Email " + connection.firstName + " to have them fill their profile"}
                                            />
                                        </div>
                                    }
                                </div>
                            </section>
                        </Col>
                        <Col xs={12} sm={6} lg={3} className="event-info-container">
                            {selectedOccasion?.occasion?.ocassionDate && new Date(selectedOccasion.occasion?.ocassionDate) && <EventInfo occasion={selectedOccasion.occasion} />}
                        </Col>
                        <Col xs={12} sm={6} lg={4} className="search-container">
                            <section className="dropdown-wrapper">
                                <BaseHolidayDropdown
                                    selectedOccasion={selectedOccasion}
                                    occasions={occasions} />
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>

    )
}

export default UserProfileContainer;
