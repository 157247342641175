import React, { createContext, useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { userState } from '../state/atoms';

const AuthenticateContext = () => {
    const history = useHistory();
    const [user, setUser] = useRecoilState(userState)

    useEffect(() => {
        window.addEventListener('load', (event) => {
            async function checkForLoggedUser() {
                await checkLoginStatus();
            }
            checkForLoggedUser();
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (user.imagePathUrl) {
                let imageResp = await fetch('api/Images/GetImage?url=' + user.imagePathUrl);
                setUser({ ...user, profilePhotoDownloadUrl: imageResp.url });
            }
        })();
    }, [user.imagePathUrl]);

    async function checkLoginStatus() {
        try {
            console.log('check for logged user');
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('development server!!!!!');
            } else {
                console.log('PRODUCTION');
            }

            const response = await axios.get('/api/Auth/CheckLogin');
            if (response.status == 200) {
                const user = response.data;
                const userInfoResponse = await fetch('/api/Users/GetCurrentUserInfo')
                let userInfo = await userInfoResponse.json()
                setUser({ ...userInfo, isLoggedIn: true, userName: userInfo.firstName + " " + userInfo.lastName });

                if (history.location.pathname == "/" || history.location.pathname == "/landing") {
                    history.push("/relationships")
                }
            }

        } catch (error) {
            console.log(`error log: ${console.error}`);
        }
        finally {
            return null;
        }
    }
    return (null);
}

export default AuthenticateContext
